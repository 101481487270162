import React, { useState, useEffect } from 'react'
import Collapse from '@material-ui/core/Collapse'
import { t } from '../utils'
import authors from '../utils/authors.json'
import config from '../config.json'

const Authors = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
    let aa = []
    for (let i in config.authors) {
      aa.push(authors[config.authors[i]])
    }
    setAuthorsArray([...aa])
  }, [])

  const [authorsArray, setAuthorsArray] = useState([])

  const toggleAuthor = (rowIndex, authorIndex) => {
    let a = authorsArray
    a[rowIndex * 2 + authorIndex].expanded = !a[rowIndex * 2 + authorIndex].expanded
    setAuthorsArray([...a])
  }

  const renderUrl = (url, urlLabel) => {
    return (
      <a href={url} target="_blank" className="btn btn--red authors w-inline-block">
        <h4 className="nav__h">{urlLabel}</h4>
        <div className="growing-div"></div>
      </a>
    )
  }

  const authorBlock = (author, authorIndex, rowIndex) => {
    return (
      <div key={`author_${author.handle}`} className="col">
        <div className="a-hero__cont">
          <div className="card-title">
            <div className="a-img-wrap"><img src={`images/${author.image}`} alt="" className="a-img" /></div>
            <div className="a-name">
              <h3 className="a-title">{t(`authors.name_${author.handle}`)}</h3>
              {author.url && author.urlLabel && renderUrl(author.url, author.urlLabel)}
            </div>
          </div>
          <div className="a__g-line"></div>
          <ul className="list">
            {author.list.map((item, ii) => {
              return <li key={`author_${author.name}_list_${ii}`} className="bullet-author">{t(`authors.exp_${item}`)}<br /></li>
            })}
          </ul>
          <div className="accordion-wrap">
            <Collapse in={author.expanded}>
              <div className="a-hero__text">
                <p className="a-hero__p2">{t(`authors.description_${author.handle}`)}</p>
              </div>
            </Collapse>
            <div onClick={() => toggleAuthor(rowIndex, authorIndex)} href="#" className="a-hero__link w-inline-block">
              <div className="card-link">
                <h4 className="cont__h4 cont__h4--authors">{t('authors.show_full')}</h4><img src="images/r-arrow.svg" alt="arrow-img" width="12" className={`arrow-img ${author.expanded ? 'flipped' : ''}`} /></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const authorRows = () => {
    let rows = []
    let row = []
    for (let i in authorsArray) {
      row.push(authorsArray[i])
      if (i % 2 == 1 || i == authorsArray.length - 1) {
        rows.push(row)
        row = []
      }
    }
    return rows
  }

  return (
    <div className="sec-wrap">
      <h4 className="sec__title">{t('authors.title')}</h4>
      <div className="sec">
        <div className="container">
          <h1 className="hero__h">{t('authors.subtitle')}</h1>
          {authorRows().map((row, ri) => {
            return (
              <div key={`row_${ri}`} className="row row--author">
                { row.map((author, ai) => authorBlock(author, ai, ri))}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const styles = {
  arrow: { "WebkitTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90DEG) skew(0, 0)", "MozTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90DEG) skew(0, 0)", "MsTransform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90DEG) skew(0, 0)", "transform": "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(90DEG) skew(0, 0)" },
  description: { "WebkitTransform": "translate3d(0, 70PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", "MozTransform": "translate3d(0, 70PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", "MsTransform": "translate3d(0, 70PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)", "transform": "translate3d(0, 70PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" }
}

export default Authors