import React from 'react'
import config from '../config.json'

const CategoryHome = (props) => {

  const { category, onClick } = props
  const { name, icon, image, id } = category

  return (    
    <div className="col col--hero">
      <div onClick={onClick} className="hero-link w-inline-block">
        <div className="hero-cont">
          <div className="hero-img-wrap" style={{ backgroundImage: `url(${image})` }}></div>
          {props.renderPaymentLabel(id)}
          <div className="title-wrap">
            <h4 className="cont__h4">{ name }</h4>
            {config.partner === 'tKQgHPEaRHz1bMAlCBzk'
              ? <img src="/images/hero-arrow-right-beci.svg" alt="arrow-img" width="10" className="arrow-img" />
              : <img src="/images/hero-arrow-right.svg" alt="arrow-img" width="10" className="arrow-img" />
            }
          </div>
          <div className="icon-wrap"><img src={icon} alt="category-icon" className="hero-icon"/></div>
        </div>
      </div>
    </div>
  )
}

export default CategoryHome