import React, { Fragment } from 'react'
import { EmailOutlined, PhoneOutlined, Facebook, LinkedIn, Twitter } from '@material-ui/icons'

import config from '../config.json'

const Header = ({ navbar, languages }) => {
  return (
    <Fragment>
      <div className={`header-top header-top--${config.class_handle}`}>
        <div className="container">
          <div className="header-top_inner">
            <div className="languages">
              { languages() }
            </div>
            <ul className="social">
              <li><a href="https://www.linkedin.com/company/beci/?originalSubdomain=fr"><LinkedIn /></a></li>
              <li><a href="https://www.facebook.com/beci.brussels/"><Facebook /></a></li>
              <li><a href="https://twitter.com/beci_brussels"><Twitter /></a></li>
            </ul>
            <ul className="contacts">
              <li><a href="mailto:info@beci.be"><EmailOutlined /> <span>info@beci.be</span></a></li>
              <li><a href="tel:026485002"><PhoneOutlined /> <span>02/648.50.02</span></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`header-bottom header-bottom--${config.class_handle}`}>
        { navbar(true, true) }
      </div>
    </Fragment>
  )
}

export default Header