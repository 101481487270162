import React from 'react' 
import { Link } from 'react-router-dom'

import { t } from '../utils'
import config from '../config.json'

const FooterBeci = () => {
  return (
    <footer className={`footer-2 footer-2--${config.class_handle}`}>
      <div className="footer-2__top">
        <div className="container">
          <div className="content">
            <div className="logo">
              <img src="/images/footer_logo.png" alt="Beci" />
            </div>
            <ul>
              <li><Link to="/">{t('nav.home')}</Link></li>
              <li><Link to="/documents">{t('nav.documents')}</Link></li>
              <li><Link to="/contact">{t('nav.contact')}</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-2__bottom">
        <div className="container">
          <div className="content">
            <div className="box">
              <div className="brand-logo">
                <img src="/images/lawstudio_logo.svg" alt="Lawstudio" />
                <p>lawstud.IO</p>
              </div>
              <p>legal.beci.be is powered by <a href="https://lawstud.io/" target="_blank" rel="noreferrer">lawstud.io</a></p>
            </div>
            <div className="copyright">
              <p>
                &copy;{new Date().getFullYear()} {t('footer.copyright')} <span className="divider">-</span> <Link to="/privacy-policy">{t('footer.privacy_policy')}</Link> <span className="divider">-</span> <Link to="/terms">{t('footer.terms_of_use')}</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterBeci