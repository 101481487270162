import React, { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, DialogTitle, Modal } from '@material-ui/core'

import { updateUser } from "../services/database";
import { updateUserPassword } from "../services/auth";

import { companyTypeOptions, t } from "../utils";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import config from "../config.json";
import firebase from '../services/firebase'

const Profile = (props) => {
  const { startLoading, stopLoading, showSnackbar, getUser, history } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [user, setUser] = useState({});

  const [passwordDialog, setPasswordDialog] = useState({
    isOpen: false,
    newPassword: "",
    newVerificationPassword: "",
  });
  const [showDeleteProfileAlert, setShowDeleteProfileAlert] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [deleteProfileError, setDeleteProfileError] = useState('')

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const updateField = (handle, value) => {
    setUser({ ...user, [handle]: value });
  };

  const submit = async () => {
    const loadingKey = "savingProfile";
    startLoading(loadingKey, t("profile.saving"));

    let result = await updateUser(user);

    if (!!result.error) {
      showSnackbar({
        text: t(`firebase_error.${result.error.code}`),
        color: "error",
      });
    }
    stopLoading(loadingKey);
    getUser();
  };

  const openPasswordDialog = () => {
    setPasswordDialog({
      isOpen: true,
      newPassword: "",
      newVerificationPassword: "",
    });
  };

  const closePasswordDialog = () => {
    setPasswordDialog({
      isOpen: false,
      newPassword: "",
      newVerificationPassword: "",
    });
  };

  const submitPasswordDialog = async () => {
    let loadingKey = "updatingPassword";
    startLoading(loadingKey);
    if (passwordDialog.newPassword !== passwordDialog.newVerificationPassword) {
      showSnackbar({ text: t("auth.password_mismatch"), color: "error" });
      stopLoading(loadingKey);
      return;
    }
    closePasswordDialog();
    let result = await updateUserPassword(passwordDialog.newPassword);
    if (result.error) {
      showSnackbar({
        text: t(`firebase_error.${result.error.code}`),
        color: "error",
      });
      stopLoading(loadingKey);
      return;
    }
    showSnackbar({ text: t("profile.password_changed"), color: "success" });
    stopLoading(loadingKey);
  };

  const companyTypeCountry = () => {
    return config.company_type_country ? config.company_type_country : 'be'
  }

  // Delete profile
  const deleteProfileHandler = async () => {
    const { uid, email } = firebase.auth().currentUser
    if(oldPassword.trim() === '') {
      return setDeleteProfileError(t('profile.password_validate'))
    }
    startLoading('profileDelete')
    const credential = firebase.auth.EmailAuthProvider.credential(email, oldPassword.trim())
    try {
      await firebase.auth().currentUser.reauthenticateWithCredential(credential)
      await firebase.firestore().collection('users').doc(uid).delete()
      await firebase.auth().currentUser.delete()
      showSnackbar({ text: t('profile.account_deleted'), color: "success" })
      history.push('/login')
    } catch (err) {
      console.log(err)
      setDeleteProfileError(err.message)
    }

    stopLoading('profileDelete')
  }

  // Old password change handler
  const oldPasswordChangeHandler = (e) => {
    setDeleteProfileError('')
    setOldPassword(e.target.value)
  }

  return (
    <div className="sec">
      <div className="container">
        <div
          data-duration-in="300"
          data-duration-out="100"
          className="tabs-2 w-tabs"
        >
          {/* <div className="tabs-menu w-tab-menu">
            <a data-w-tab="Tab 1" data-w-id="1bcbaaec-68c5-db20-063b-4cab0d781127" className="tab__link w-inline-block w-tab-link w--current">
              <div className="tab-link__title">Mes données</div>
              <div className="tab-link__line"></div>
            </a>
            <a data-w-tab="Tab 2" data-w-id="1bcbaaec-68c5-db20-063b-4cab0d78112b" className="tab__link w-inline-block w-tab-link">
              <div className="tab-link__title">Mes documents à générer</div>
              <div className="tab-link__line"></div>
            </a>
          </div> */}
          <div className="w-tab-content">
            <div data-w-tab="Tab 1" className="tab1 w-tab-pane w--tab-active">
              <div className="c-form__cont">
                <div className="c-form-wrap">
                  <div className="form w-form">
                    <div
                      id="email-form"
                      name="email-form"
                      data-name="Email Form"
                    >
                      <div className="f__row">
                        <div className="c-form__wrap">
                          <label htmlFor="Title" className="f-form__label">
                            {t("profile.title")}
                          </label>
                          <input
                            value={safeValue(user.title, "string")}
                            onChange={(e) =>
                              updateField("title", e.target.value)
                            }
                            type="text"
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="Title"
                            data-name="Title"
                            id="Title"
                            required=""
                            placeholder="Mr / Ms / Mrs"
                          />
                        </div>
                      </div>
                      <div className="f__row">
                        <div className="c-form__wrap">
                          <label htmlFor="Nom" className="f-form__label">
                            {t("general.name_last")}
                          </label>
                          <input
                            value={safeValue(user.name_last, "string")}
                            onChange={(e) =>
                              updateField("name_last", e.target.value)
                            }
                            type="text"
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="Nom"
                            data-name="Nom"
                            id="Nom"
                            required=""
                          />
                        </div>
                        <div className="c-form__wrap2">
                          <label htmlFor="Prenom" className="f-form__label">
                            {t("general.name_first")}
                          </label>
                          <input
                            value={safeValue(user.name_first, "string")}
                            onChange={(e) =>
                              updateField("name_first", e.target.value)
                            }
                            type="text"
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="Prenom"
                            data-name="Prenom"
                            id="Prenom"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="f__row">
                        <div className="c-form__wrap">
                          <label htmlFor="Title-4" className="f-form__label">
                            {t("profile.street")}
                          </label>
                          <input
                            value={safeValue(user.address_street, "string")}
                            onChange={(e) =>
                              updateField("address_street", e.target.value)
                            }
                            type="text"
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="Title-2"
                            data-name="Title 2"
                            id="Title-2"
                            required=""
                          />
                        </div>
                        <div className="c-form__wrap2">
                          <label htmlFor="Title-4" className="f-form__label">
                            {t("profile.number")}
                          </label>
                          <input
                            value={safeValue(user.address_number, "number")}
                            onChange={(e) =>
                              updateField("address_number", e.target.value)
                            }
                            type="number"
                            className="c-form__text-field2 w-input"
                            maxLength="256"
                            name="Title-3"
                            data-name="Title 3"
                            id="Title-3"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="f__row">
                        <div className="c-form__wrap">
                          <label htmlFor="Ville" className="f-form__label">
                            {t("profile.city")}
                          </label>
                          <input
                            value={safeValue(user.address_city, "string")}
                            onChange={(e) =>
                              updateField("address_city", e.target.value)
                            }
                            type="text"
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="Ville"
                            data-name="Ville"
                            id="Ville"
                            required=""
                          />
                        </div>
                        <div className="c-form__wrap2">
                          <label
                            htmlFor="Code-Postal"
                            className="f-form__label"
                          >
                            {t("profile.zip")}
                          </label>
                          <input
                            value={safeValue(user.address_zip, "number")}
                            onChange={(e) =>
                              updateField("address_zip", e.target.value)
                            }
                            type="number"
                            className="c-form__text-field2 w-input"
                            maxLength="256"
                            name="Code-Postal"
                            data-name="Code Postal"
                            id="Code-Postal"
                            required=""
                          />
                        </div>
                      </div>
                      {config.partner === 'tKQgHPEaRHz1bMAlCBzk' && <div className="f__row">
                        <div className="c-form__wrap">
                          <label
                            htmlFor="enterprise_number"
                            className="f-form__label"
                          >
                            {t("auth.enterprise_number")}
                          </label>
                          <input
                            value={safeValue(user.enterprise_number, "number")}
                            onChange={(e) => updateField("enterprise_number", e.target.value)}
                            type="number"
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="enterprise_number"
                            data-name="NUMÉRO D'ENTREPRISE"
                            id="enterprise_number"
                            required
                          />
                        </div>
                      </div>}
                      <div className="f__row">
                        <div className="c-form__wrap">
                          <label
                            htmlFor="Num-ro-de-TVA"
                            className="f-form__label"
                          >
                            {t("profile.vat")}
                          </label>
                          <input
                            value={safeValue(user.vat, "string")}
                            onChange={(e) => updateField("vat", e.target.value)}
                            type="text"
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="Num-ro-de-TVA"
                            data-name="Numéro de TVA"
                            id="Num-ro-de-TVA"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="f__row">
                        <div className="c-form__wrap">
                          <label htmlFor="Email-3" className="f-form__label">
                            {t("general.email")}
                          </label>
                          <input
                            type="email"
                            value={safeValue(user.email, "email")}
                            onChange={(e) =>
                              updateField("email", e.target.value)
                            }
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="Email"
                            data-name="Email"
                            id="Email-3"
                            required=""
                          />
                        </div>
                        <div className="c-form__wrap2">
                          <label htmlFor="T-l-phone" className="f-form__label">
                            {t("contact.phone")}
                          </label>
                          <input
                            value={safeValue(user.phone, "string")}
                            onChange={(e) =>
                              updateField("phone", e.target.value)
                            }
                            type="tel"
                            className="c-form__text-field w-input"
                            maxLength="256"
                            name="T-l-phone"
                            data-name="Téléphone"
                            id="T-l-phone"
                            required=""
                          />
                        </div>
                      </div>
                      {config.has_company_fields ? (
                        <>
                          <div className="f__row">
                            <div className="c-form__wrap">
                              <label
                                htmlFor="company_name"
                                className="f-form__label"
                              >
                                {t("profile.company_name")}
                              </label>
                              <input
                                type="text"
                                value={safeValue(user.company_name)}
                                onChange={(e) =>
                                  updateField("company_name", e.target.value)
                                }
                                className="c-form__text-field w-input"
                                maxLength="256"
                                name="company_name"
                                data-name="company_name"
                                id="company_name"
                              />
                            </div>
                            <div className="c-form__wrap2">
                              <FormControl>
                                <label
                                  htmlFor="company_type"
                                  className="f-form__label"
                                >
                                  {t("profile.company_type")}
                                </label>
                                <Select
                                  labelId="company_type"
                                  id="company_type"
                                  value={safeValue(
                                    user.company_type,
                                    "company_type"
                                  )}
                                  onChange={(e) =>
                                    updateField("company_type", e.target.value)
                                  }
                                >
                                  <MenuItem value={"none"} disabled>
                                    {t("subscription.select")}
                                  </MenuItem>
                                  { companyTypeOptions[companyTypeCountry()].map((option, oi) => {
                                    return (
                                      <MenuItem
                                        key={`companyTypeOption${oi}`}
                                        value={option}
                                      >
                                        {t(
                                          `profile.company_type_${companyTypeCountry()}_${option}`
                                        )}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="f__row">
                            <div className="c-form__wrap">
                              <label
                                htmlFor="company_website"
                                className="f-form__label"
                              >
                                {t("profile.company_website")}
                              </label>
                              <input
                                type="text"
                                value={safeValue(user.company_website)}
                                onChange={(e) =>
                                  updateField("company_website", e.target.value)
                                }
                                className="c-form__text-field w-input"
                                maxLength="256"
                                name="company_website"
                                data-name="company_website"
                                id="company_website"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="c-btn-wrap">
                        <h5 className="c-form__h5">{t("general.password")}</h5>
                        <div
                          onClick={openPasswordDialog}
                          className="c-btn btn--red w-inline-block"
                        >
                          <h4 className="nav__h">
                            {t("profile.change_password")}
                          </h4>
                          <div className="growing-div"></div>
                        </div>
                        {/* <h5 className="c-form__h5">Mon compte</h5>
                        <a data-w-id="bce14da4-2f20-a79f-845a-16200ffeb763" href="#" className="c-btn btn--red w-inline-block">
                          <h4 className="nav__h">{ t('profile.delete') }</h4>
                          <div className="growing-div"></div>
                        </a> */}
                        <div className="c-btn-line-wrap">
                          <div
                            onClick={submit}
                            className="c-form__btn btn--full pop-up__btn w-button"
                          >
                            {t("general.save")}
                          </div>
                          <div className="red-line"></div>
                        </div>
                        {config.partner === 'tKQgHPEaRHz1bMAlCBzk' && <div className="c-btn-line-wrap">
                          <div
                            onClick={() => setShowDeleteProfileAlert(true)}
                            className="c-btn remove"
                          >
                            {t("profile.delete")}
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div data-w-tab="Tab 2" className="w-tab-pane"></div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={passwordDialog.isOpen}
        onEscapeKeyDown={closePasswordDialog}
      >
        <div className={`pop-up pop-up--${config.class_handle}`}>
          <div className="pop__cont">
            <div className="pop__title-wrap">
              <h1 className="pop__h1">{t("profile.change_title")}</h1>
              <div onClick={closePasswordDialog}>
                <img
                  src={config.partner === 'tKQgHPEaRHz1bMAlCBzk' ? "images/x-2-beci.svg" : "images/x-2.svg"}
                  alt="close-icon"
                  data-w-id="1d4b146a-1c8e-82d3-1f24-545fc4a8b32e"
                  className="close-img"
                />
              </div>
            </div>
            <div className="pop__form-wrap">
              <div className="w-form">
                <div id="email-form" name="email-form" data-name="Email Form">
                  <label htmlFor="email" className="pop__form-label">
                    {t("profile.new_password")}
                  </label>
                  <input
                    value={passwordDialog.newPassword}
                    onChange={(e) => {
                      setPasswordDialog({
                        ...passwordDialog,
                        newPassword: e.target.value,
                      });
                    }}
                    type="password"
                    className="pop__text-field w-input"
                    maxLength="256"
                    name="newpassword"
                    id="newpassword"
                    required=""
                  />
                  <label htmlFor="email" className="pop__form-label">
                    {t("profile.verify_password")}
                  </label>
                  <input
                    value={passwordDialog.newVerificationPassword}
                    onChange={(e) => {
                      setPasswordDialog({
                        ...passwordDialog,
                        newVerificationPassword: e.target.value,
                      });
                    }}
                    type="password"
                    className="pop__text-field w-input"
                    maxLength="256"
                    name="newpassword2"
                    id="newpassword2"
                    required=""
                  />
                  <div className="btn-wrap">
                    <div
                      onClick={closePasswordDialog}
                      className="btn-line-wrap"
                    >
                      <div className="pop-up__btn btn--full w-button">
                        {t("general.cancel")}
                      </div>
                      <div className="red-line"></div>
                    </div>
                    <div
                      onClick={submitPasswordDialog}
                      className="btn-line-wrap"
                    >
                      <div className="pop-up__btn btn--full w-button">
                        {t("profile.change_password")}
                      </div>
                      <div className="red-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* DELETE PROFILE */}
      <Dialog
        open={showDeleteProfileAlert && config.partner === 'tKQgHPEaRHz1bMAlCBzk'}
        onClose={() => setShowDeleteProfileAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`dialog--${config.class_handle}`}
      >
        <DialogTitle id="alert-dialog-title">{`${t('profile.delete_profile')} ?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t('profile.delete_info')}
          </DialogContentText>
          <div>
            <label className="pop__form-label">{t('profile.enter_password')}</label>
            <input type="password" value={oldPassword} onChange={oldPasswordChangeHandler} className="pop__text-field w-input" />
            {deleteProfileError && <p className="form-error">{deleteProfileError}</p>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteProfileAlert(false)} color="default">
            {t('general.cancel')}
          </Button>
          <Button onClick={deleteProfileHandler} color="secondary">
            {t('profile.delete')}
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

const safeValue = (value, type = "string") => {
  if (!value) {
    return defaultValue(type);
  } else {
    return value;
  }
};

const defaultValue = (type) => {
  switch (type) {
    case "string":
      return "";
    case "number":
      return "";
    case "company_type":
      return "none";
    default:
      return "";
  }
};

export default Profile;
