import React from 'react';

const Switch = ({ disabled, checked, onChange }) => {
  return (
    <div className="switch-el">
      <label>
        <input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
        <div className="switch-el__btn"></div>
      </label>
    </div>
  );
}

export default Switch;