import React, { useState, useEffect, useRef } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";

import { Question, Auth, PreviewSkeleton } from "../components";

import { createDocument, createPreview } from "../services/functions";

import { listenForUserDocument, updateUserDraft } from "../services/database";

import { isUserSignedIn } from "../services/auth";

import Dialog from "@material-ui/core/Dialog";

import { t, ReactGA, userDataFields } from "../utils";

import config from "../config.json";

// import { pdfjs, Document, Page } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import Pdf from "@mikecousins/react-pdf";

const DocumentForm = (props) => {
  const { startLoading, stopLoading, showSnackbar } = props;
  const [doc, setDoc] = useState(null);
  const [draftId, setDraftId] = useState("");
  const [blocks, setBlocks] = useState([]);
  const [filteredBlocks, setFilteredBlocks] = useState([]);
  const [currentBlock, setCurrentBlock] = useState(-1);
  const [progress, setProgress] = useState(0);
  const [answers, setAnswers] = useState({});
  const [complete, setComplete] = useState(false);
  const [preview, setPreview] = useState({
    data: null,
    numberOfPages: 0,
  });
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [auth, setAuth] = useState({
    isOpen: false,
    title: "",
    onRegister: () => { },
    onLogin: () => { },
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight - 100,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const { documents, location } = props;
    let id = props.match.params.id;
    let d;
    if (!id) {
      id = "";
      d = null;
      setDoc(null);
      setAnswers({});
      return;
    } else {
      d = documents[id];
    }
    let ans = {};
    let answers = {};
    let drftId = undefined;
    if (!!location.state && !!location.state.draftId) {
      answers = location.state.answers;
      drftId = location.state.draftId;
    }
    setupDocument(id, d, answers, drftId);
  }, [props.documents]);

  useEffect(() => {
    refreshAnswers();
  }, [props.user]);

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      nextBlock();
    }
  };

  useEffect(() => {
    props.refreshInteractions();
  });

  useEffect(() => {
    const { history, documents } = props
    const id = props.match.params.id
    const document = documents[id]
    if (document && props.shouldPushToSubscription(document.category)) {
      const link = { pathname: '/subscription', state: { documentId: document.id } }
      history.push(link)
    }
  }, [])

  const setupDocument = (id, d, answrs, drftId) => {
    if (!d || !!doc) {
      return;
    }

    let blcks = d.form;

    let currentBlock = -1;

    for (let i in blcks) {
      let blockComplete = true;
      for (let j in blcks[i].questions) {
        if (!answrs[blcks[i].questions[j].key]) {
          blockComplete = false;
          answrs[blcks[i].questions[j].key] = defaultAnswerForQuestion(
            blcks[i].questions[j]
          );
        }
      }
      if (!blockComplete && currentBlock === -1) {
        currentBlock = i;
      }
    }

    let fb = [];
    for (let bi in blcks) {
      let b = blcks[bi];
      if (!!b.condition) {
        let blockCompliesToConditions = true;
        let condition = conditionForKey(d, b.condition);
        if (!condition) {
          blockCompliesToConditions = true;
        } else if (condition.relation === "eq") {
          blockCompliesToConditions =
            answrs[condition.question] === condition.value;
        } else if (condition.relation === "less") {
          blockCompliesToConditions =
            answrs[condition.question] < parseInt(condition.value);
        } else if (condition.relation === "more") {
          blockCompliesToConditions =
            answrs[condition.question] > parseInt(condition.value);
        } else if (condition.relation === "cont") {
          blockCompliesToConditions = !!answrs[condition.question]
            ? answrs[condition.question].indexOf(condition.value) !== -1
            : false;
        }
        if (!blockCompliesToConditions) {
          continue;
        }
        if (blockCompliesToConditions) {
          fb.push(b);
        }
      } else {
        fb.push(b);
      }
    }

    setDoc(d);
    setBlocks([...blcks]);
    setFilteredBlocks([...fb]);
    setAnswers({ ...answrs });
    setDraftId(drftId);
    setCurrentBlock(0);
  };

  const previousBlock = () => {
    setupBlock(-1);
  };

  const nextBlock = () => {
    setupBlock(+1);
  };

  const reviewForm = () => {
    setupBlock(-blocks.length);
  };

  const setupBlock = (offset) => {
    let newBlock = currentBlock + offset;
    let fb = [];
    for (let bi in blocks) {
      let b = blocks[bi];
      if (!!b.condition) {
        let blockCompliesToConditions = true;
        let condition = conditionForKey(doc, b.condition);
        if (!condition) {
          blockCompliesToConditions = true;
        } else if (condition.relation === "eq") {
          blockCompliesToConditions =
            answers[condition.question] === condition.value;
        } else if (condition.relation === "less") {
          blockCompliesToConditions =
            answers[condition.question] < parseInt(condition.value);
        } else if (condition.relation === "more") {
          blockCompliesToConditions =
            answers[condition.question] > parseInt(condition.value);
        } else if (condition.relation === "cont") {
          blockCompliesToConditions = !!answers[condition.question]
            ? answers[condition.question].indexOf(condition.value) !== -1
            : false;
        }
        if (!blockCompliesToConditions) {
          continue;
        }
        if (blockCompliesToConditions) {
          fb.push(b);
        }
      } else {
        fb.push(b);
      }
    }

    let blocksCount = fb.length;

    let cmplt;

    let prgrss;

    if (newBlock >= blocksCount) {
      newBlock = blocksCount - 1;
      cmplt = true;
      prgrss = 1;
    } else if (newBlock < 0) {
      newBlock = 0;
      cmplt = false;
      prgrss = 0;
    } else {
      prgrss = newBlock / blocksCount;
      cmplt = false;
    }

    setFilteredBlocks([...fb]);
    setCurrentBlock(newBlock);
    setProgress(prgrss);
    setComplete(cmplt);

    scrollToTop();
  };

  const showPreview = async () => {
    // startLoading('preview', t('form.preview_generation'))
    setPreview({
      data: null,
      numberOfPages: 0,
    });
    setLoadingPreview(true);

    scrollToBottom();

    let result = await createPreview(doc, answers);
    if (result.error) {
      showError(
        !!result.error.message
          ? result.error.message
          : "An error occured when generating document preview. Please try again later"
      );
      setLoadingPreview(false);
      return;
    }

    setPreview({
      data: result.data,
      numberOfPages: 0,
    });

    ReactGA.event({
      category: "document",
      action: "Preview created",
    });

    setLoadingPreview(false);
  };

  const showError = (message) => {
    showSnackbar({ text: message, color: "error" });
  };

  const trackOnLogin = () => {
    ReactGA.event({
      category: "auth",
      action: "User signed in",
      label: "Document creation",
    });
  };

  const trackOnRegister = () => {
    ReactGA.event({
      category: "auth",
      action: "User registered",
      label: "Document creation",
    });
  };

  const trackOnCreateDocument = () => {
    ReactGA.event({
      category: "document",
      action: "Document generated",
    });
  }

  const saveDocument = async () => {
    if (!isUserSignedIn()) {
      openAuth(
        t("form.auth_document"),
        () => {
          closeAuth();
          saveDocument();
          trackOnRegister();
        },
        () => {
          closeAuth();
          saveDocument();
          trackOnLogin();
        }
      );
      return;
    }

    const {
      setGeneratingDocument,
      setGeneratingDocumentCancelled,
      getUserDocuments,
    } = props;

    startLoading("creatingDocument", t("form.loading"));

    trackOnCreateDocument()
    let result = await createDocument(doc, answers);
    if (!!draftId) {
      await updateUserDraft(null, draftId);
    }

    stopLoading("creatingDocument");
    getUserDocuments();

    setGeneratingDocumentCancelled(false);

    let listener = listenForUserDocument(result.documentId, (data) => {
      setGeneratingDocument({ ...data, listener: listener });      
      if (!!data.files && !!data.files) {
        let complete = true;
        for (let key in data.files) {
          if (data.files[key].generation_progress < 1) {
            complete = false;
          }
        }
        if (complete) {
          getUserDocuments();
          clearInterval(listener);
        }
      }
    });
  };

  const saveDraft = async () => {
    const { getUserDrafts } = props;
    if (!isUserSignedIn()) {
      openAuth(
        t("form.auth_draft"),
        () => {
          closeAuth();
          saveDraft();
          trackOnRegister();
        },
        () => {
          closeAuth();
          saveDraft();
          trackOnLogin();
        }
      );
      return;
    }

    startLoading("savingDraft");
    let draft = {
      name: doc.name,
      answers: answers,
      document: doc.id,
    };

    let result = await updateUserDraft(draft, draftId);
    if (result.id) {
      setDraftId(result.id);
    }
    stopLoading("savingDraft");
    getUserDrafts();
    if (!result.error) {
      showSnackbar({ text: t("form.draft_success"), color: "success" });
    } else {
      showSnackbar({ text: result.error.message, color: "error" });
    }
  };

  const conditionForKey = (d, key) => {
    for (let i in d.conditions) {
      if (d.conditions[i].key === key) {
        return d.conditions[i];
      }
    }
    return null;
  };

  const refreshAnswers = () => {
    if (!doc) {
      return;
    }
    let blcks = doc.form;
    let answrs = answers;
    for (let i in blcks) {
      for (let j in blcks[i].questions) {
        if (!answrs[blcks[i].questions[j].key]) {
          answrs[blcks[i].questions[j].key] = defaultAnswerForQuestion(
            blcks[i].questions[j]
          );
        }
      }
    }
    setAnswers({ ...answrs });
  };

  const defaultAnswerForQuestion = (question) => {
    switch (question.type) {
      case "string": {
        if (question.prefill_user_data) {
          return defaultAnswerWithUserData(question.user_data);
        }
        return "";
      }
      case "number":
        return "";
      case "date":
        return null;
      case "multiselect":
        return [];
      case "select":
        return 0;
      case "table":
        return defaultAnswerForTable(question);
      default:
        return ''
    }
  };

  const companyTypeCountry = () => {
    return config.company_type_country ? config.company_type_country : 'be'
  }

  const defaultAnswerWithUserData = (template) => {
    const { user } = props;
    if (!user || Object.keys(user).length === 0) {
      return "";
    }
    if(!template) {
      return ""
    }
    let res = template;
    for (let i in userDataFields) {
      let handle = userDataFields[i].handle;
      
      let val = user[handle];
      if (!val) {
        val = "";
      }
      if(val && handle === 'company_type') {
        val = t(`profile.company_type_${companyTypeCountry()}_${val}`)
      }
      res = res.replace(`%{${handle}}`, val);
    }
    return res;
  };

  const defaultAnswerForTable = (question) => {
    let answer = {};
    // for(let i in question.sections) {
    //   for(let j in question.sections[i].rows) {
    //     answer[question.sections[i].rows[j]] = undefined
    //   }
    // }
    return answer;
  };

  const onAnswerChanged = (value, questionKey) => {
    let answrs = answers;
    answrs[questionKey] = value;
    setAnswers({ ...answrs });
  };

  const openAuth = (message, onRegister, onLogin) => {
    setAuth({
      isOpen: true,
      title: message,
      onRegister,
      onLogin,
    });
  };

  const closeAuth = () => {
    setAuth({
      isOpen: false,
      title: "",
    });
  };

  return (
    <div className="sec">
      {!!doc ? (
        <div className="container">
          <h1 className="hero__h">{doc.name}</h1>
          <div className="p-bar-wrap">
            <h4 className="p-bar-title">{`${(progress * 100).toFixed(0)}% ${t(
              "general.progress"
            )}`}</h4>
            <LinearProgress
              color="primary"
              value={progress * 100}
              variant="determinate"
            />
          </div>
          <div className="c-form__cont">
            {!complete &&
              currentBlock >= 0 &&
              currentBlock < filteredBlocks.length ? (
                <div className="q-form-wrap">
                  <div className="form w-form">
                    <div id="doc-form" name="doc-form" data-name="Doc Form">
                      <h2 className="q-form__title">
                        {" "}
                        {`${currentBlock + 1}. ${filteredBlocks[currentBlock].title
                          }`}{" "}
                      </h2>
                      {filteredBlocks[currentBlock].questions.map(
                        (question, qi) => {
                          return (
                            <Question
                              key={`question_${question.key}`}
                              question={question}
                              index={qi}
                              onChange={(value) =>
                                onAnswerChanged(value, question.key)
                              }
                              answer={answers[question.key]}
                            />
                          );
                        }
                      )}
                      <div className="download-title-wrap">
                        <div
                          onClick={saveDraft}
                          className="icon-text-wrap w-inline-block close-icn"
                        >
                          <img
                            src="/images/save.svg"
                            alt="download-icon"
                            className="download-icon"
                          />
                          <h4 className="download-title">
                            {t("form.save_draft")}
                          </h4>
                        </div>
                        <div
                          onClick={showPreview}
                          className="icon-text-wrap2 w-inline-block"
                        >
                          <img
                            src="/images/show.svg"
                            alt="download-icon"
                            className="preview-icon"
                          />
                          <h4 className="download-title">{t("form.preview")}</h4>
                        </div>
                      </div>
                      <div className="q-btn-wrap">
                        <div
                          onClick={previousBlock}
                          className="q-btn1 btn--red w-inline-block"
                        >
                          <div className="text-block-4">{t("form.previous")}</div>
                        </div>
                        <div
                          onClick={nextBlock}
                          className="q-btn btn--red w-inline-block"
                        >
                          <h4 className="nav__h-2 nav__h--white">
                            {t("form.next")}
                          </h4>
                          <div className="growing-div-2"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!!preview.data ? (
                    <div className="pdf-container">
                      <Pdf
                        className="hidden-pdf"
                        file={JSON.parse(
                          JSON.stringify(
                            `data:application/pdf;base64,${preview.data}`
                          )
                        )}
                        onDocumentLoadSuccess={(doc) => {
                          setPreview({
                            ...preview,
                            numberOfPages: doc._pdfInfo.numPages,
                          });
                        }}
                        page={-1}
                      />
                      {preview.numberOfPages > 0
                        ? [...Array(preview.numberOfPages)].map((page, pi) => {
                          return (
                            <div
                              key={`preview_page_${pi}`}
                              className="preview-container"
                            >
                              <Pdf
                                file={`data:application/pdf;base64,${preview.data}`}
                                className="pdf-page"
                                page={pi + 1}
                              />
                              {config.env !== "development" &&
                                config.use_watermark ? (
                                  <div className="watermark-container">
                                    <div className="watermark" />
                                  </div>
                                ) : null}
                            </div>
                          );
                        })
                        : null}
                    </div>
                  ) : loadingPreview ? (
                    <PreviewSkeleton />
                  ) : null}
                </div>
              ) : (
                <div className="q-form-wrap">
                  {t("form.complete")}
                  <div className="q-btn-wrap">
                    <div
                      onClick={reviewForm}
                      className="q-btn1 btn--red w-inline-block"
                    >
                      <div className="text-block-4">{t("form.review")}</div>
                    </div>
                    <div
                      onClick={saveDocument}
                      className="q-btn btn--red w-inline-block"
                    >
                      <h4 className="nav__h-2 nav__h--white">
                        {t("form.get_document")}
                      </h4>
                      <div className="growing-div-2"></div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : null}
      <Dialog
        scroll="body"
        maxWidth="lg"
        open={auth.isOpen}
        onEscapeKeyDown={closeAuth}
      >
        {/* <div className="pop-up"> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Auth
            title={auth.title}
            startLoading={startLoading}
            stopLoading={stopLoading}
            showSnackbar={showSnackbar}
            onLogin={auth.onLogin}
            onPasswordReset={() => { }}
            onRegister={auth.onRegister}
            getUser={props.getUser}
            onClose={closeAuth}
          />
        </div>
        {/* </div> */}
      </Dialog>
    </div>
  );
};

export default DocumentForm;
