import React from 'react'

import config from '../config.json'

import { t, currentLanguage } from '../utils'

const PartnersFooter = () => {

  const partnerUrl = (p) => {
    let url = p.url
    url = url.replace('%{lang}', currentLanguage)
    return url
  }

  return (
    <div>
      <div className="title-wrap2">
        <div className="thin-line"></div>
        <h5 className="hero__h5">{ t('home.partners') }</h5>
        <div className="thin-line"></div>
      </div>
      <div className="partners-row">
        { config.partners.map((p, pi) => {
          return (
            <div key={`partner_${pi}`} className="partner-col-wrap">
              <a href={partnerUrl(p)} className="partner-col">
                <img src={`/images/partner_${p.handle}_logo.png`} alt="" className="partner-logo"/>
              </a>
              { pi < config.partners.length - 1 ? (
                <span className="partner-col-joint">{ '&' }</span>
              ) : null }
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PartnersFooter