import React from "react";
import { ColoredLine } from "../components";
import { t } from "../utils";
import { Button, Typography, CardContent, Card } from "@material-ui/core";
import config from "../config.json";
const Subscription = ({ name, price, content, onClickSelect, index }) => {
  const coloredLineStyles = {
    color: "#000000",
    margin: "30px 0",
  };
  let contentSplitted = content.split('\n')
  return (
    <Card>
      <CardContent>
        {/** title */}
        <Typography
          className={`subscription__product__item__name subscription__product__item__name--${config.class_handle}`}
          variant="h5"
          component="h2"
        >
          {name}
        </Typography>
        {/** pay */}
        <Typography
          variant="h5"
          component="h2"
          className="subscription__product__item__pay "
        >
          {`${config.partner === 'VNIQuk0to4ahhQyzgagz' ? '490' : price} € ${t('price.ht')}`}
        </Typography>
        {/** ColoredLine */}
        <ColoredLine style={coloredLineStyles} />
        {/** content */}
        {contentSplitted.map((c, i) => <Typography key={i} className="subscription__product__item__content">{c}</Typography>)}
        {/** button */}
        <Button
          variant="contained"
          color="primary"
          className="subscription__product__item__button"
          onClick={() =>{ config.partner === 'VNIQuk0to4ahhQyzgagz' ? window.location.href = 'https://calendly.com/support-dataprivacybox/rendez-vous-assistance-rgpd-simply-move' : onClickSelect(index) }}
        >
          {t("subscription.select")}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Subscription;
