import React from "react";
import { t } from '../../utils'

const BillingDetail = (props) => {
    const { billingDetails, handleOnChange } = props
    return (
        <div className="form w-form" style={{ width: '100%' }}>

             <div className="f__row">
                <div className="f__cont-wrap">
                    <label htmlFor="title" className="f-form__label">{t('profile.title')}</label>
                    <input placeholder="Mr / Ms / Mrs" type="text" value={billingDetails.title} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="title" data-name="title" id="title" required="" />
                </div>
            </div>

            <div className="f__row">
                <div className="f__cont-wrap">
                    <label htmlFor="firstName" className="f-form__label">{t('general.name_first')}</label>
                    <input type="text" value={billingDetails.firstName} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="firstName" data-name="firstName" id="firstName" required="" />
                </div>
                <div className="f__cont-wrap f__cont-wrap--right">
                    <label htmlFor="lastName" className="f-form__label">{t('general.name_last')}</label>
                    <input type="text" value={billingDetails.lastName} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="lastName" data-name="lastName" id="lastName" required="" />
                </div>
            </div>

            <div className="f__row">
                <div className="f__cont-wrap">
                    <label htmlFor="street" className="f-form__label">{t('profile.street')}</label>
                    <input type="text" value={billingDetails.street} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="street" data-name="street" id="street" required="" />
                </div>
                <div className="f__cont-wrap f__cont-wrap--right">
                    <label htmlFor="city" className="f-form__label">{t('profile.city')}</label>
                    <input type="text" value={billingDetails.city} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="city" data-name="city" id="city" required="" />
                </div>
            </div>

            <div className="f__row">
                <div className="f__cont-wrap">
                    <label htmlFor="postalCode" className="f-form__label">{t('profile.zip')}</label>
                    <input type="text" value={billingDetails.postalCode} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="postalCode" data-name="postalCode" id="postalCode" required="" />
                </div>
                <div className="f__cont-wrap f__cont-wrap--right">
                    <label htmlFor="numberTVA" className="f-form__label">{t('profile.vat')}</label>
                    <input type="string" value={billingDetails.numberTVA} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="numberTVA" data-name="numberTVA" id="numberTVA" required="" />
                </div>
            </div>

            <div className="f__row">
                <div className="f__cont-wrap">
                    <label htmlFor="phone" className="f-form__label">{t('contact.phone')}</label>
                    <input type="text" value={billingDetails.phone} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="phone" data-name="phone" id="phone" required="" />
                </div>
                <div className="f__cont-wrap f__cont-wrap--right">
                    <label htmlFor="email" className="f-form__label">{t('general.email')}</label>
                    <input type="text" value={billingDetails.email} onChange={handleOnChange} className="f-form__text-field w-input" maxLength="256" name="email" data-name="email" id="email" required="" />
                </div>
            </div>

        </div>
    )
}

export default BillingDetail