import React from 'react'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import { t } from '../utils'
import TableInput from './TableInput'


const Question = (props) => {

  const { question, answer, onChange } = props
  const renderTextInput = () => {
    return(
      <input value={answer} onChange={e => onChange(e.target.value)} type="text" className="q-form__text-fiel w-input" maxLength="256" />
    )
  } 

  const renderNumberInput = () => {
    return (
      <input value={answer} onChange={e => onChange(e.target.value)} type="number" className="q-form__text-fiel w-input" maxLength="256" />
    )
  }

  const renderDateInput = () => {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          className="date-picker-container"
          autoOk
          variant="inline"
          emptyLabel={t('general.select_date')}
          format="DD/MM/YYYY"
          value={!!answer ? moment(answer) : null}
          onChange={date => onChange(date.valueOf())}
        />
      </MuiPickersUtilsProvider>
    )
  }

  const handleMultiselectOptionChange = (option, checked) => {
    let a = [...answer]
    if(checked && a.indexOf(option) === -1) {
      a.push(option)
    } else if(!checked && a.indexOf(option) !== -1) {
      a.splice(a.indexOf(option), 1)
    }
    onChange(a)
  }

  const renderMultiselectInput = (question) => {
    return question.options.map(( option, oi) => {
      return (
        <label key={`question_${question.key}_option_${oi}`} className="w-checkbox">
          <div className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox-3 ${answer.indexOf(oi) !== -1 ? 'w--redirected-checked' : ''}`}></div>
          <input checked={answer.indexOf(oi) !== -1} onChange={ e => handleMultiselectOptionChange(oi, e.target.checked) } type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} />
          <span className="checkbox-label-2 w-form-label">{ option }</span>
        </label>
      )
    })
  }

  const renderSelectInput = (question) => {
    return question.options.map(( option, oi) => {
      return (
        <label className="w-radio" key={`question_${question.key}_option_${oi}`}>
          {/* <div className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input w--redirected-checked"></div> */}
          <input checked={answer === oi} onChange={e => onChange(parseInt(e.target.value))} className="w-form-formradioinput w-form-formradioinput--inputType-custom radio-button w-radio-input" type="radio" data-name={`radio_${question.key}`} id={`radio_${question.key}`} name={`radio_${question.key}`} value={`${oi}`} />
          <span className="radio-button-label w-form-label">{ option }</span>
        </label>
      )
    })
  }

  const renderInput = (question) => {
    switch(question.type) {
      case 'string':
        return renderTextInput()      
      case 'number':
          return renderNumberInput()
      case 'date':
        return renderDateInput()
      case 'multiselect':
        return renderMultiselectInput(question)
      case 'select':
        return renderSelectInput(question)
    }
  }

  if(!!question) {
    if(question.type !== 'table') {
      return (
        <div className="f__row">
          <div className="q-form__wrap">
            <label htmlFor="Title" className="f-form__label">
              { `${question.text}` }
              <p className="legend">{ question.description }</p>
            </label>
            { renderInput(question) }
          </div>
        </div>
      )
    } else {
      return (
        <TableInput question={question} answer={answer} onChange={onChange} />
      )
    }
  } else {
    return (
      <div></div>
    )
  }
}

export default Question