import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm/CheckoutForm';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,

} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { t } from '../utils'
import BillingDetail from '../components/BillingDetail/BillingDetail';
import config from '../config.json'

const STRIPE_PK = { 'development': 'pk_test_dxYJ1c51cihp0to1MrbXHHvu00Y398JKci', 'staging': 'pk_test_dxYJ1c51cihp0to1MrbXHHvu00Y398JKci', 'production': 'pk_live_tXMrcBhLUEH9c8BK2ceCUsOd00w0cCaZ6b' }
const stripePromise = loadStripe(STRIPE_PK[config.env]);

const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
        },
    ],
};

const Payment = (props) => {
    const [billingDetails, setBillingDetails] = useState({
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        city: '',
        postalCode: '',
        street: '',
        numberTVA: '',
        title: ''
    });
    const [product, setProduct] = useState({
        name: '',
        type: '',
        price: 0,
        id: ''
    })

    useEffect(() => {
        const { history, user } = props
        if (!Object.keys(user).length) history.push('/login')
        const product = history.location.state
        setBillingDetails(prevState => ({
            ...prevState,
            title: user.title,
            firstName: user.name_first,
            lastName: user.name_last,
            phone: user.phone,
            email: user.email,
            postalCode: user.address_zip,
            city: user.address_city,
            numberTVA: user.vat,
            street: `${user.address_number || ''} ${user.address_street || ''}`
        }))
        product && setProduct(history.location.state)
    }, [])

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setBillingDetails(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    return (
        <div className="sec payment-wrapper" style={{ padding: '120px 0' }}>
            <div className="container">
                <Accordion expanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography >1. {t('cart.myCart')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('cart.name')}</TableCell>
                                        <TableCell align="right">{t('cart.type')}</TableCell>
                                        <TableCell align="right">{t('cart.price')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {product.name}
                                        </TableCell>
                                        <TableCell align="right">{product.type}</TableCell>
                                        <TableCell align="right">{`${product.price}€`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {t('price.vat_label')}
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">{`${product.price * 0.2}€`}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {t('price.total_label')}
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">{`${product.price * 1.2}€`}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
                {/** */}
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography >2. {t('cart.billingDetails')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BillingDetail
                            billingDetails={billingDetails}
                            handleOnChange={handleOnChange}
                        />
                    </AccordionDetails>
                </Accordion>
                {/** */}
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography >3. {t('cart.payment')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
                            <CheckoutForm {...props} billingDetails={billingDetails} product={product} />
                        </Elements>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

export default Payment;