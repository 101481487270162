import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
} from "@stripe/react-stripe-js";
import { t } from "../../utils";
import "./CheckoutForm.css";
import { payment, confirmPayment } from "../../services/functions";
import config from "../../config.json";

const CARD_OPTIONS = {
  hidePostalCode: true,
  iconStyle: "solid",
  style: {
    base: {
      color: "#32325D",
      fontWeight: 500,
      fontFamily: "Source Code Pro, Consolas, Menlo, monospace",
      fontSize: "16px",
      fontSmoothing: "antialiased",

      "::placeholder": {
        color: "#CFD7DF",
      },
      ":-webkit-autofill": {
        color: "#e39f48",
      },
    },
    invalid: {
      color: "#E25950",

      "::placeholder": {
        color: "#FFCCA5",
      },
    },
  },
};

const SubmitButton = ({ error, disabled, setButton, name }) => (
  <button
    className={`submitButton submitButton--${config.class_handle} ${name} ${
      error ? "submitButton--error" : ""
    }`}
    type="submit"
    disabled={disabled}
    onClick={() => name && setButton(name)}
  >
    {t(`cardForm.${name}`)}
  </button>
);

const CheckoutForm = (props) => {
  const {
    showSnackbar,
    startLoading,
    stopLoading,
    billingDetails,
    product,
    history,
    checkHasPaid,
  } = props;
  const {
    firstName,
    lastName,
    city,
    email,
    numberTVA,
    phone,
    postalCode,
    street,
    title,
  } = billingDetails;
  const stripe = useStripe();
  const elements = useElements();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(null);
  const [button, setButton] = useState(null);

  const handleError = (err) => {
    const message = err.message;
    setError(err);
    showSnackbar({ text: message, color: "warning" });
    stopLoading();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    startLoading();
    const cardElement = elements.getElement(CardElement);
    const tokenResponse = await stripe.createToken(cardElement);
    if (tokenResponse.error) return handleError(tokenResponse.error);
    const tokenId = tokenResponse.token.id;
    const paymentMethodResponse = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        phone,
        email,
        address: {
          city,
          postal_code: postalCode,
          line1: `${street}`,
        },
        name: `${title} ${firstName} ${lastName}`,
      },
      metadata: {
        numberTVA,
      },
    });
    if (paymentMethodResponse.error)
      return handleError(paymentMethodResponse.error);
    const paymentMethodId = paymentMethodResponse.paymentMethod.id;
    const payload = {
      amount: product.price,
      paymentMethodId,
      subscriptionId: product.id,
    };
    try {
      const capture = await payment(payload);
      if (!capture) {
        stopLoading();
        return;
      }
      if (capture.status === "requires_source_action" && capture.clientSecret) {
        const cardActionResponse = await stripe.handleCardAction(
          capture.clientSecret
        );
        if (cardActionResponse.error)
          return handleError(cardActionResponse.error);
        await confirmPayment({
          paymentIntentId: capture.paymentIntentId,
          subscriptionId: product.id,
        });
      }
      await checkHasPaid();
      const link = product.documentId
        ? `/document-form/${product.documentId}`
        : "payment-success";
      history.push(link);
    } catch (error) {
      return handleError(error);
    }
    stopLoading();
  };

  const fields = [
    {
      name: "card",
      component: (
        <CardElement
          options={CARD_OPTIONS}
          onChange={(event) => setError(event.error)}
        />
      ),
    },
    // {
    //     name: "numberCard",
    //     component: <CardNumberElement
    //         options={CARD_OPTIONS}
    //         onChange={(event) => setError(event.error)}
    //     />
    // },
    // {
    //     name: "expireDate",
    //     component: <CardExpiryElement
    //         options={CARD_OPTIONS}
    //         onChange={(event) => setError(event.error)}
    //     />
    // },
    // {
    //     name: "CVC",
    //     component: <CardCvcElement
    //         options={CARD_OPTIONS}
    //         onChange={(event) => setError(event.error)}
    //     />
    // }
  ];

  const submitButtons = [
    {
      name: "submitThisCard",
    },
    // {
    //     name: "submitExistCard"
    // }
  ];

  return (
    <form onSubmit={handleSubmit}>
      <h3>{t("cardForm.header")}</h3>
      {/** card field */}
      {fields.map((field, index) => (
        <div className="row" key={index}>
          <div className="field">
            <label>{t(`cardForm.${field.name}`)}</label>
            {field.component}
          </div>
        </div>
      ))}
      {/** checkbox */}
      {/* <div className="row">
                <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                <label className="saveCard">{t('cardForm.saveCard')}</label>
            </div> */}
      {/** submit button */}
      {submitButtons.map((submitButton, index) => (
        <div key={index}>
          <SubmitButton
            error={error}
            disabled={!stripe}
            setButton={setButton}
            name={submitButton.name}
          />
        </div>
      ))}
    </form>
  );
};

export default CheckoutForm;
