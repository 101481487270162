import React from 'react'

const TermsOfUse = ({ title }) => {
  return (
    <div className="sec">
      <div className="container">
        <div className="terms-page">
          <h1>{title}</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus sint, quam dolorem, totam deserunt odit alias molestiae earum, neque nihil dolor recusandae modi corrupti quidem reiciendis repudiandae ipsa? Et, in corrupti provident voluptates exercitationem dolorum? Suscipit, quam nam omnis, maxime, aliquam sunt dignissimos voluptatibus facilis mollitia odio dicta necessitatibus magni!</p>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum doloribus unde impedit corporis. Minus fuga obcaecati nobis et asperiores ex aliquam ratione reprehenderit numquam iusto.</p>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, eligendi? Consectetur iure esse quaerat animi corporis, provident laboriosam dolore qui in quas velit repudiandae molestias praesentium tempore accusamus modi veniam.</p>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUse