import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

const PreviewSkeleton = (props) => {

  const sections = [
    [
      { width: '40%' },
      { width: '40%' },
      { width: '40%' },
      { width: '40%' },
      { width: '40%' }
    ],
    [
      { width: '90%' },
      { width: '90%' },
      { width: '90%' },
      { width: '90%' },
      { width: '90%' },
      { width: '90%' },
      { width: '90%' }
    ],
    [
      { width: '90%' },
      { width: '90%' },
      { width: '90%' },
      { width: '90%' },
      { width: '90%' }
    ]
  ]

  return (

    <div className="pdf-container">
      <div className="preview-container">
        <div className="pdf-page">
          { sections.map((s, si) => {
            return (
              <div key={`skeleton_section_${si}`} className="preview-skeleton-section">
                { s.map((row, ri) => {
                  return (
                    <LinearProgress key={`skeleton_row_${si}_${ri}`} className={`title-wrap-skeleton-line col-${((si+ri)%3)+1}`} style={{ width: row.width }} />
                  )
                })}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )

}

export default PreviewSkeleton