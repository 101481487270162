import React, { Fragment } from "react";

import config from "../config.json";

import { t, currentLanguage } from "../utils";

const Footer = (props) => {
  const { navigate } = props;

  const openDoc = (docName) => () => {
    window.open(`/docs/${docName}(${currentLanguage()}).pdf`);
  };

  return (
    <div className={`footer footer--${config.class_handle}`}>
      <div className="f-cont__wrapper">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="footer-cont-wrap">
                <h3 className="footer__h">{config.name}</h3>
                <div className="footer-link__wrap">
                  <div
                    onClick={navigate("/")}
                    data-w-id="2b4d349e-ad6a-6951-250a-d0fe4430011f"
                    href="index.html"
                    className={`footer__link footer__link--${config.class_handle}  w--current`}
                  >
                    {t("nav.home")}
                  </div>
                  <div
                    className={`footer__line footer__line--${config.class_handle}`}
                  ></div>
                </div>
                {!config.single_document ? (
                  <div className="footer-link__wrap">
                    <div
                      onClick={navigate("/documents")}
                      data-w-id="2b4d349e-ad6a-6951-250a-d0fe44300123"
                      className={`footer__link footer__link--${config.class_handle}  w--current`}
                    >
                      {t("nav.documents")}
                    </div>
                    <div
                      className={`footer__line footer__line--${config.class_handle}`}
                    ></div>
                  </div>
                ) : null}
                {/* { !config.single_document ? (
                  <div className="footer-link__wrap"><div onClick={navigate('/authors')} data-w-id="2b4d349e-ad6a-6951-250a-d0fe44300127" className="footer__link">{ t('nav.authors') }</div>
                    <div className="footer__line"></div>
                  </div>
                ) : null } */}
                {config.authors && config.authors.length > 0 && (
                  <div className="footer-link__wrap">
                    <div
                      onClick={navigate("/authors")}
                      data-w-id="2b4d349e-ad6a-6951-250a-d0fe44300127"
                      className="footer__link"
                    >
                      {t("nav.authors")}
                    </div>
                    <div className="footer__line"></div>
                  </div>
                )}
                <div className="footer-link__wrap">
                  <div
                    onClick={navigate("/contact")}
                    data-w-id="2b4d349e-ad6a-6951-250a-d0fe4430012b"
                    className={`footer__link footer__link--${config.class_handle}  w--current`}
                  >
                    {t("nav.contact")}
                  </div>
                  <div
                    className={`footer__line footer__line--${config.class_handle}`}
                  ></div>
                </div>
                {config.simplymove_footer_links && (
                  <div className="footer-link__wrap">
                    <a
                      href={"/docs/PDP.pdf"}
                      className={`footer__link footer__link--${config.class_handle}  w--current`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {"Vie privée"}
                    </a>
                    {/* <div
                      onClick={() =>
                        window.open(
                          "https://www.simply-move.fr/fr/p_privacy-policy"
                        )
                      }
                      className={`footer__link footer__link--${config.class_handle}  w--current`}
                    >
                      {"Vie privée"}
                    </div> */}
                    <div
                      className={`footer__line footer__line--${config.class_handle}`}
                    ></div>
                  </div>
                )}
                {config.simplymove_footer_links && (
                  <div className="footer-link__wrap">
                    <a
                      href={"/docs/CGU.pdf"}
                      className={`footer__link footer__link--${config.class_handle}  w--current`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {"Conditions générale"}
                    </a>
                    {/* <div
                      onClick={() =>
                        window.open(
                          "https://www.simply-move.fr/fr/p_conditions-generales-dutilisation"
                        )
                      }
                      className={`footer__link footer__link--${config.class_handle}  w--current`}
                    >
                      {"Conditions générale"}
                    </div> */}
                    <div
                      className={`footer__line footer__line--${config.class_handle}`}
                    ></div>
                  </div>
                )}
                {config.partner === 'tKQgHPEaRHz1bMAlCBzk' && 
                  <Fragment>
                    <div className="footer-link__wrap">
                      <div
                        onClick={navigate("/privacy-policy")}
                        className={`footer__link footer__link--${config.class_handle}  w--current`}
                        data-w-id="2b4d349e-ad6a-6951-250a-d0fe4430012b"
                      >
                        {t("footer.privacy_policy")}
                      </div>
                      <div
                        className={`footer__line footer__line--${config.class_handle}`}
                      ></div>
                    </div>
                    <div className="footer-link__wrap">
                      <div
                        onClick={navigate("/terms")}
                        className={`footer__link footer__link--${config.class_handle}  w--current`}
                        data-w-id="2b4d349e-ad6a-6951-250a-d0fe4430012b"
                      >
                        {t("footer.terms_of_use")}
                      </div>
                      <div
                        className={`footer__line footer__line--${config.class_handle}`}
                      ></div>
                    </div>
                  </Fragment>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`under-footer under-footer--${config.class_handle}`}>
        <div className="under-footer-content">
          <h5 className="footer__h4">{`©2021 ${t("footer.copyright")}`}</h5>
          {config.footer_documents
            ? config.footer_documents.map((doc) => {
                return (
                  <div key={`doc_${doc}`} className="footer-link__wrap">
                    <div
                      onClick={openDoc(doc)}
                      className="footer__link footer__h4"
                    >
                      {t(`footer.${doc}`)}
                    </div>
                    <div className="footer__line"></div>
                  </div>
                );
              })
            : null}
        </div>
        <div className="footer-link__wrap">
          <a
            href="https://lawstud.io"
            target="_blank"
            className={`footer__link footer__h4 footer__link--${config.class_handle}`}
          >
            {"Powered by Lawstud.io ©"}
          </a>
          <div className={`footer__line footer__line--${config.class_handle}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
