import firebase from 'firebase/app'
require("firebase/auth")
require("firebase/firestore")
require("firebase/functions")

const firebaseConfig = {
  "apiKey": "AIzaSyD5NNjoAM13JuABMDFzPFSXOQDZ1EenANQ",
  "authDomain": "lawstud-io.firebaseapp.com",
  "databaseURL": "https://lawstud-io.firebaseio.com",
  "projectId": "lawstud-io",
  "storageBucket": "lawstud-io.appspot.com",
  "appId": "1:151424735139:web:f32e07ca798c3ad0cf2c16",
  "measurementId": "G-2P58GXP1PQ"
}

firebase.initializeApp(firebaseConfig);

export default firebase