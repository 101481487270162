import React, { Component } from 'react'
import { currentLocale, printDate, t } from '../utils'
import config from '../config.json'

class DocumentDetail extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: '',
      document: null
    }
  }

  componentWillReceiveProps(props) {
    const { id, document } = this.documentFromProps(props)
    this.setupDocument(id, document)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { id, document } = this.documentFromProps(this.props)
    this.setupDocument(id, document)
  }

  documentFromProps = (props) => {
    const { documents } = props
    let id = props.match.params.id
    if (!id) {
      return { id: '', document: null }
    }
    return {
      id: id,
      document: documents[id]
    }
  }

  setupDocument = (id, document) => {
    if (!id) {
      return
    }
    if(!document) {
      return
    }
    if(document.meta.locale !== currentLocale()) {
      this.props.setLocale(document.meta.locale)
    }
    this.setState({
      id,
      document
    })
  }


  openQuestionnaire = () => {
    const { history, shouldPushToSubscription } = this.props
    const { id, document } = this.state
    const link = shouldPushToSubscription(document.category) ? { pathname: '/subscription', state: { documentId: document.id } } : `/document-form/${document.id}`
    history.push(link)
  }

  render() {
    const { document } = this.state
    return (
      !!document ? (
        <div className="sec">
          <div className="container">
            <div className="row row--author">
              <div className="col d-col">
                <div className="doc-cont-wrap1">
                  <h1 className="d-hero__h">{document.name}</h1>
                  <p className="d-hero__title">{`${t('document.duration')}: `}<span className="d-hero__span">{`${document.duration} min`}</span></p>
                  <p className="d-hero__title">{`${t('document.last_update')}: `}<span className="d-hero__span">{printDate(document.meta.updated)}</span></p>
                  { Boolean(document.author) && <p className="d-hero__title">{`${t('document.authors')}: `}<span className="d-hero__span">{document.author}</span></p> }
                  <p className="d-hero__p">
                    {document.description}
                  </p>
                </div>
              </div>
              <div className="col d-col--little">
                <div className="d-btn-wrap">
                  <div className="d__btn-line-wrap"><div onClick={this.openQuestionnaire} className="d-btn btn--full w-button">{t('document.create')}</div>
                    <div className="red-line"></div>
                  </div>
                </div>
                <div className="doc-cont-wrap">
                  <div className="d-cont__title-wrap"><img src="/images/info-1.svg" alt="info-icon" className="info-icon" />
                    <p className="d-hero__title--little">{t('document.help')}</p>
                  </div>
                  <div className="d-cont__text-wrap">
                    <p className="d-hero__title--little">{t('document.step1_title')}</p>
                    <p className="d-hero__p--sidebar">{t('document.step1_description')}</p>
                  </div>
                  <div className="d-cont__text-wrap">
                    <p className="d-hero__title--little">{t('document.step2_title')}</p>
                    <p className="d-hero__p--sidebar">{t('document.step2_description')}</p>
                  </div>
                  <div className={`d-cont__text-wrap${!config.has_advocates ? '--last' : ''}`}>
                    <p className="d-hero__title--little">{t('document.step3_title')}</p>
                    <p className="d-hero__p--sidebar">{t('document.step3_description')}</p>
                  </div>
                  {config.has_advocates ? (
                    <div className="d-cont__text-wrap--last">
                      <p className="d-hero__title--little">{t('document.step4_title')}</p>
                      <p className="d-hero__p--sidebar">{t('document.step4_description')}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
          <div></div>
        )
    )
  }
}

export default DocumentDetail