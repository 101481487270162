import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

const CategorySkeleton = (props) => {

  return (
    <div className="col col--hero">
      <div className="hero-link w-inline-block">
        <div className="hero-cont">
          <div className="hero-img-wrap-skeleton"></div>
          <div className="title-wrap-skeleton">

            <LinearProgress className={`title-wrap-skeleton-line first col-${props.col}`} />
            <LinearProgress className={`title-wrap-skeleton-line col-${props.col}`} />
          </div>
          <div className="icon-wrap"><div className="hero-icon-skeleton" /></div>
        </div>
      </div>
    </div>
  )

}

export default CategorySkeleton