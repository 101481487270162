import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from '@material-ui/core'
import { t } from '../utils'
import { ColoredLine, Subscription } from "../components";
import classNames from 'classnames'

import { getSubscriptions } from "../services/database";

const Subscriptions = (props) => {
    const coloredLineStyles = {
        color: '#000000',
    }
    const { history } = props
    const [selectedProductIndex, setSelectedProductIndex] = useState(null)
    const [loading, setLoading] = useState(false)
    const [subscriptions, setSubscriptions] = useState([])
    const productItemClass = (currentIndex) => {
        return classNames({
            subscription__product__item: true,
            active: currentIndex === selectedProductIndex
        })
    }
    const onClickSelect = (currentIndex) => {
        setSelectedProductIndex(currentIndex)
        history.push({
            pathname: '/payment',
            state: {
                ...subscriptions[selectedProductIndex || 0],
                ...history.location.state ? history.location.state : {}
            }

        })
    }

    useEffect(() => {
        setLoading(true)
        const getSubscriptionData = async () => {
            const result = await getSubscriptions()
            if (result) {
                const subscriptions = []
                Object.keys(result).map(item => {
                    subscriptions.push(
                        {
                            ...result[item],
                            name: t(`${result[item].key}.name`),
                            content: t(`${result[item].key}.content`)
                        }
                    )
                })
                setSubscriptions(subscriptions)
                setLoading(false)
            }
        }
        getSubscriptionData()
    }, [])

    return (
        <Box
            className="subscription"
            padding="50px 0"
        >
            {/** subscription__header */}
            <Box
                className="subscription__header"
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                width="50%"
                margin="auto"
                padding="20px 0"
            >
                <Box width="20%">
                    <ColoredLine style={coloredLineStyles} />
                </Box>
                <Box width="40%">
                    <h4>{t('subscription.header')}</h4>
                </Box>
                <Box width="20%">
                    <ColoredLine style={coloredLineStyles} />
                </Box>
            </Box>
            {/** */}

            {/** subscription__product */}
            <Box
                className="subscription__product"
                display="flex"
                justifyContent="space-around"
            >
                { loading ? <CircularProgress /> : null }
                {
                    subscriptions.map(
                        (subscription, index) => (
                            <Box width="40%" className={productItemClass(index)} key={index}>
                                <Subscription
                                    {...subscription}
                                    index={index}
                                    onClickSelect={onClickSelect}
                                />
                            </Box >

                        )
                    )
                }
            </Box>
        </Box>
    )
}

export default Subscriptions