import React, { useState } from 'react'

// import cookieImg from '../../assets/images/cookie.png'
import Button from './UI/Button'
import Switch from './UI/Switch'
import Modal from './UI/Modal'
// import t from '../../i18n'

import { currentLanguage, t } from '../utils'
import config from '../config.json'

const Cookies = ({ shouldAcceptCookies, setCookiesChanged }) => {
  const [showPreferences, setShowPreferences] = useState(false)
  const [analytics, setAnalytics] = useState(false)
  
  const saveCookies = (type = '') => {
    let obj
    switch(type) {
      case 'all':
        obj = { required: true, tracking: true }
        break
      case 'necessary':
        obj = { required: true, tracking: false }
        break
      case '':
        obj = { required: true, tracking: analytics }
        break
      default:
        obj = { required: true, tracking: analytics }
    }
    
    shouldAcceptCookies(obj)
    setCookiesChanged(true)
  }

  const linkCookie = `docs/cookies_policy(${currentLanguage()}).pdf`

  return (
    <div className="cookies-section-wrapper">
      <div className="landing-container">
        <div className="cookies-section">
          <img src={'/images/cookie.png'} alt="" className="cookies-section__cookie" />
          <div className="cookies-section__top">
            <p>{t('cookies.intro_1')}</p>
            <p>{t('cookies.intro_2')}</p>
          </div>
          <div className="cookies-section__actions">
            <Button text={t('cookies.toggle_1')} primary medium onButtonClick={() => saveCookies('all')} />
            <Button text={t('cookies.toggle_2')} medium onButtonClick={() => saveCookies('necessary')} />
            <Button text={t('cookies.toggle_3')} medium onButtonClick={() => setShowPreferences(true)} />
          </div>
          { (config.footer_documents && config.footer_documents.includes('cookies_policy')) && <div className="cookies-section__bottom">
            <p>
              {t('cookies.detail_text')}&nbsp;
              <a href={linkCookie} className="link" rel="noopener noreferrer" target="_blank">{t('cookies.detail_text_1')}</a>
            </p>
          </div> }
          {showPreferences && <Modal onClose={() => setShowPreferences(false)} hideCloseBtn className="cookies-modal">
            <div className="cookies-modal__body">
              <div className="cookies-modal__body_section">
                <div className="cookies-modal__body_section-top">
                  <p>{t('cookies.toggle_4')}</p>
                  <Switch checked disabled />
                </div>
                <p className="cookies-modal__body_section-text">{t('cookies.toggle_4_text')}</p>
              </div>
              <div className="cookies-modal__body_section">
                <div className="cookies-modal__body_section-top">
                  <p>{t('cookies.toggle_5')}</p>
                  <Switch onChange={(e) => setAnalytics(e.target.checked)} checked={analytics} />
                </div>
                <p className="cookies-modal__body_section-text">{t('cookies.toggle_5_text')}</p>
              </div>
              <Button text={t('cookies.save')} primary medium fullWidth onButtonClick={saveCookies} />
            </div>
          </Modal>}
        </div>
      </div>
    </div>
  )
}

export default Cookies