import React from 'react'

import { Auth } from '../components'

import { ReactGA } from '../utils'

const Login = (props) => {

  const { startLoading, stopLoading, showSnackbar } = props

  const onLogin = () => {
    navigate('/')
    ReactGA.event({
      category: 'auth',
      action: 'User signed in',
      label: 'Connect'
    })
  }

  const onRegister = () => {
    navigate('/myprofile')
    ReactGA.event({
      category: 'auth',
      action: 'User registered',
      label: 'Connect'
    })
  }

  const onPasswordReset = () => {
  }

  const navigate = (path) => {
    const { history } = props
    history.push(path)
  }

  return (
    <div className="sec" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <div className="container">
        <Auth
          startLoading={startLoading}
          stopLoading={stopLoading}
          showSnackbar={showSnackbar}
          onLogin={onLogin}
          onPasswordReset={onPasswordReset}
          onRegister={onRegister}
          getUser={props.getUser}
          updateUserType={props.updateUserType}
        />
      </div>
    </div>
  )
}

export default Login
