import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

import { t } from '../utils'

const TableInput = (props) => {

  const { question, answer, onChange } = props

  const [sectionsExpanded, setSectionsExpanded] = useState({})

  useEffect(() => {
    let se = sectionsExpanded
    for(let i in question.sections) {
      se[question.sections[i].key] = false
    }
    setSectionsExpanded({...se})
  }, [question])

  const toggleSection = (sectionKey) => () => {
    let se = sectionsExpanded
    se[sectionKey] = !se[sectionKey]
    setSectionsExpanded({...se})
  }


  const renderGeneralSelector = () => {
    let selectorButtons = []
    for(let i = 0; i < question.columns; i++) {
      selectorButtons.push(
        <Grid item key={`general_selector_${i}`}>
          <Button startIcon={iconForState(i)} variant="contained" color={generalAnswerForTable() === `${i}` ? 'primary' : 'default'} size="small" onClick={setAnswerForTable(`${i}`)}>{ t(`table.state_${i+1}`) }</Button>
        </Grid>
      )
    }

    return (
      <div className="row table-general-wrapper">
        <h3 className="table-general-title">{ t('table.state_title') }</h3>
        <div className="table-general-buttons-wrapper">
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
            { selectorButtons }
          </Grid>
        </div>
      </div>
    )
  }

  const iconForState = (s) => {
    switch(s) {
      case 0:
        return <ThumbUpIcon fontSize="small" />
      case 1:
        return <ChangeHistoryIcon fontSize="small" />
      case 2:
        return <RadioButtonUncheckedIcon fontSize="small" />
    }
  }

  const setAnswerForTable = (value) => () => {
    let a = answer
    for(let i in question.sections) {
      for(let j in question.sections[i].rows) {
        a[question.sections[i].rows[j]] = value
      }
    }
    onChange({...a})
  }

  const setAnswerForSection = (sectionKey, value) => {
    let section = sectionWithKey(sectionKey)
    let a = answer
    for(let i in section.rows) {
      a[section.rows[i]] = value
    }
    onChange({...a})
  }

  const setAnswerForRow = (rowHandle, value) => {
    let a = answer
    a[rowHandle] = value
    onChange({...a})
  } 

  const sectionWithKey = (sectionKey) => {
    for(let i in question.sections) {
      if(question.sections[i].key === sectionKey) {
        return question.sections[i]
      }
    }
  }

  const handleCheckboxChange = (key, type, value) => (e) => {
    // if(e.target.checked) {
      if(type === 'section') {
        setAnswerForSection(key, value)
      } else {
        setAnswerForRow(key, value)
      }
    // }
  }

  const renderSelector = (key, type, numberOfColumns) => {
    let selectorButtons = []
    for(let i = 0; i < numberOfColumns; i++) {
      selectorButtons.push(
        <label key={`selector_${key}_${i}`} className="w-checkbox">
          <div className={`w-checkbox-input w-checkbox-input--inputType-custom checkbox-3 ${selectorShouldBeChecked(key, type, `${i}`) ? 'w--redirected-checked' : ''}`}></div>
          <input checked={selectorShouldBeChecked(key, type, `${i}`)} onChange={handleCheckboxChange(key, type, `${i}`)} type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" style={{ opacity: 0, position: 'absolute', zIndex: -1 }} />
        </label>
      )
    }
    return (
      <div className="row">
        { selectorButtons }
      </div>
    )
  }

  const selectorShouldBeChecked = (key, type, value) => {
    if(type === 'section') {
      return generalAnswerForSection(sectionWithKey(key)) === value
    } else {
      return answer[key] === value
    }
  }

  const generalAnswerForTable = () => {
    let a = ""
    for(let s in question.sections) {
      let section = question.sections[s]
      for(let i in section.rows) {
        if(!!answer[section.rows[i]]) {
          if(!a) {
            a = answer[section.rows[i]]
          } else if(a !== answer[section.rows[i]]) {
            return ""
          }
        } else {
          return ""
        }
      }
    }
    return a
  }

  const generalAnswerForSection = (section) => {
    let sectionAnswer = ""
    for(let i in section.rows) {
      if(!!answer[section.rows[i]]) {
        if(!sectionAnswer) {
          sectionAnswer = answer[section.rows[i]]
        } else if(sectionAnswer !== answer[section.rows[i]]) {
          return ""
        }
      } else {
        return ""
      }
    }
    return sectionAnswer
  } 

  const renderSections = () => {
    return (
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={3}>
        {/* todo check if we should show headers / only 1 */}
        <Grid item sm={6}>
          <div className="table-section-header-wrapper">
            { iconForState(0) }
            { iconForState(1) }
            { iconForState(2) }
          </div>
        </Grid>
        <Grid item sm={6}>
          <div className="table-section-header-wrapper">
            { iconForState(0) }
            { iconForState(1) }
            { iconForState(2) }
          </div>
        </Grid>
        { question.sections.map((section, si) => {
          return (
            <Grid item sm={6} key={`section-wrapper_${si}`}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item sm={8}>
                  <h5>{ t(`table.section_${section.key}`) }</h5>
                </Grid>
                <Grid item sm={2}>
                  <IconButton onClick={toggleSection(section.key)}>
                    { sectionsExpanded[section.key] ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                  </IconButton>
                </Grid>
                <Grid item sm={2}>
                  { renderSelector(section.key, 'section', question.columns) }
                </Grid>
              </Grid>
              <Collapse in={sectionsExpanded[section.key]}>
                { section.rows.map((row, ri) => {
                  return (
                    <Grid container direction="row" justify="space-between" alignItems="center" key={`section_${section.key}_row_${row}`} className="table-row">
                      <Grid item sm={10}>
                        <span>{ t(`table.${row}`) }</span>
                      </Grid>
                      <Grid item sm={2}>
                        { renderSelector(row, 'row', question.columns) }
                      </Grid>
                    </Grid>
                  )
                })}
              </Collapse>
            </Grid>
          )
        }) }        
      </Grid>
    )
  }

  return (
    <div className="table-input-wrapper">
      { renderGeneralSelector() }
      { question.sections?.length > 1 ? ( <h4 className="table-details-header">{ t('table.details_title') } </h4> ) : null } 
      { renderSections() }
    </div>
  )
}

export default TableInput