import fr from './fr.json'
import en from './en.json'
import pl from './pl.json'
import nl from './nl.json'
import cookie from 'react-cookies'
import config from '../config.json'

import { currentLanguage, LOCALE_C_KEY } from './index'

const source = {
  fr,
  en,
  pl,
  nl
}

const t = (k, params, useSuffix = true) => {
  // if(!suffix && config.copy_suffix) {
  //   // console.log("key with suffix", key)
  //   return t(key, params, config.copy_suffix)
  // } else {
  //   console.log("no copy suffix")
  // }

  let key = k
  if(useSuffix && config.copy_suffix) {
    key = `${key}${config.copy_suffix}`
  }
  const language = currentLanguage()
  const components = key.split('.')
  if(!source[language]) {
    return k
    // return `Missing translation for ${key}`
  }
  const section = source[language][components[0]]
  if(!section) {
    if(useSuffix) {
      return t(k, params, false)
    }
    return k
    // return `Missing translation for ${key}`
  }
  let value = section[components[1]]
  if(!value) {
    if(useSuffix) {
      return t(k, params, false)
    }
    return k
    // return `Missing translation for ${key}`
  }
  if(!!params) {
    for(let p in params) {
      value = value.replace(`%{${p}}`, params[p])
    }
  }
  return value
}

const saveLocale = (l) => {
  cookie.save(LOCALE_C_KEY, l)
}

export default t
export { saveLocale }